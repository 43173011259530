<template>
  <div id="login_wrap">
    <div class="login-box">
      <div class="login-box-bg"/>
      <div class="input-wrap">
        <form class="login-form mb-5">
          <input v-model="username" type="text" placeholder="Username" class="w-100 pt-3 pb-2" @keyup="enterLogin">
          <input v-model="password" type="password" placeholder="Password" class="w-100 pt-3 pb-2" @keyup="enterLogin">
        </form>
        <v-btn width="100%" flat rounded="0" to="/" @click="processLogin">
          LOGIN
        </v-btn>
      </div>
      <div class="action-wrap w-100 d-flex flex-wrap mt-2 flex-column">
        <div class="w-100 text-center">
          <router-link to="/signup">
            <span class="text-13 text-grey">회원가입</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="w-full text-center text-caption text-grey-darken-2 position-absolute bottom-0 pb-4">
      <p>상호명 : (주)보하비 | 대표이사 : 이성준</p>
      <p>본사 : 서울특별시 송파구 송파대로 201, 테라타워2 A 동 G212</p>
      <p>사업자등록번호 : 411-87-02965</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginView',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'refreshUser']),
    async processLogin () {
      const url = '/login'
      const form = new FormData()
      form.append('j_username', this.username)
      form.append('j_password', this.password)
      await this.$axios.post(url, form, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
      }).then(res => {
        console.log(res.data)
        if (res.data.status === 'SUCCESS') {
          this.refreshUser().then(() => {
            this.$router.push('/home')
          })
        } else {
          alert('사용자가 존재하지 않거나 패스워드가 일치하지 않습니다.')
        }
      }).catch(() => {
        alert('사용자가 존재하지 않거나 패스워드가 일치하지 않습니다.')
      })
    },
    enterLogin () {
      if (event.keyCode === 13) {
        this.processLogin()
      }
    }
  }
}
</script>

<style scoped lang="scss">
#login_wrap {
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;

  .login-box {
    max-width: 400px;
    width: 90%;
    position: relative;
    padding: 10px;

    .login-box-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .input-wrap {
      position: relative;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 100%;

      .v-input::v-deep .v-field__input {
        padding-bottom: 10px;
      }

      .login-form {
        width: 100%;

        input {
          color: white;
          border-bottom: 1px solid grey;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .action-wrap {
      z-index: 1;
      position: relative;
    }
  }
}

</style>
